 ::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }

  div:not(.parallax-view)::-webkit-scrollbar {
    display: none;
  }
  
  /* Track */
  #parallax-view:not::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Handle */
  #parallax-view:not::-webkit-scrollbar-thumb {
    display: none;
  }
  
  /* Handle on hover */
  #parallax-view:not::-webkit-scrollbar-thumb:hover {
    display: none;
  }